<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
        <el-alert title="操作提示" type="success" class="margin-bottom">
            <div>
                1. 标识“*”的选项为必填项，其余为选填项。<br />
                2. 请按提示栏信息进行商品添加。
            </div>
        </el-alert>
        <div class="box">
            <div class="form_box">
                <el-form ref="form" :model="form" label-width="120px" v-loading="loading">
                    <el-form-item label="选择年月" :rules="rules.required">
                        <el-date-picker
                                v-model="changeTime"
                                type="month"
                                format="yyyy 年 MM 月"
                                value-format="yyyy-MM"
                                placeholder="选择年月"
                                @change="getTime"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上月结余" :rules="rules.required">
                        <el-input-number v-model="form.last_month" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="现金金额" :rules="rules.required">
                        <el-input-number v-model="form.cash" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="刷卡金额" :rules="rules.required">
                        <el-input-number v-model="form.card" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="上交金额" :rules="rules.required">
                        <el-input-number v-model="form.hand_in" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="支出金额" :rules="rules.required">
                        <el-input-number v-model="form.expenditure" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="支出备注" :rules="rules.required">
                        <el-input type="textarea" v-model="form.expenditure_remark"></el-input>
                    </el-form-item>
                    <el-form-item label="结余金额" :rules="rules.required">
                        <el-input-number v-model="form.balance" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                    </el-form-item>
                    <el-form-item label="结余备注" :rules="rules.required">
                        <el-input type="textarea" v-model="form.balance_remark"></el-input>
                    </el-form-item>
                    <el-form-item label="填表人" :rules="rules.required">
                        <el-input v-model="form.manager"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()">保存</el-button>
                        <!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
                        <el-button @click="$router.back()">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import rules from '../../../common/common.rules.js'
    export default {
        data() {
            return {
                rules,
                classify: [],
                loading: false,
                changeTime:'',
                form: {
                    id: this.$route.params.id, // int 服务ID
                    year: '', // string 年份
                    month: '', // string 月份
                    last_month: 0, // string 上月结余
                    cash: 0, // string 现金金额
                    card: 0, // string 刷卡金额
                    expenditure: 0, // string 支出金额
                    hand_in: 0, // string 上交金额
                    balance: 0, // string 结余金额
                    manager:'',//填表人
                    balance_remark:'',//结余备注
                    expenditure_remark:'',//支出备注
                }
            };
        },
        computed: {
            title() {
                if (this.form.id) {
                    return '编辑现金月报'
                } else {
                    return '新增现金月报'
                }
            }
        },
        mounted() {
            if (this.form.id) {
                this.$api.table.cashMonthDetails({
                    id: this.form.id
                }).then(res => {
                    console.log(res)
                    this.form = res.data
                    this.changeTime = res.data.year + '-' + res.data.month
                })
            }
        },
        methods: {
            getTime(){
                console.log(this.changeTime)
                this.form.year = this.changeTime.substring(0,4)
                this.form.month = this.changeTime.substring(this.changeTime.length - 2,this.changeTime.length)
                console.log(this.form.year)
                console.log(this.form.month)
            },
            onSubmit() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        const loading = this.$loading();
                        this.$api.table.saveCashMonth(this.form).then(res => {
                            loading.close();
                            if (res.code == 200) {
                                // 保存成功
                                this.$message.success(`保存成功`);
                                this.$router.back();
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(err => {
                            loading.close();
                            this.$alert(err);
                        });
                    } else {
                        this.$message.warning(`请检查输入错误`);
                        return false;
                    }
                });
            }
        },
    };
</script>

<style scoped lang="scss">
    .form_box {

        .el-input,
        .el-textarea {
            width: 500px;
        }
    }

</style>
